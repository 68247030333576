import styled from "styled-components";
import { colors } from "../../components/Constants";
import { Link } from "react-router-dom";
import { useState } from "react";
import React from "react";
import { LuPhone, LuRedo, LuMail, LuAlertOctagon } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  BoxContent,
  LoadingIcon,
  Title,
  Button,
} from "../../components/StyledComponent";
const SupportTerms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <Title>Terms and Conditions</Title>
      <Description>Site terms of use</Description>
      <PolicyCard>
        {/* 1 */}
        <SectionTitle>1. ACCEPTANCE OF TERMS</SectionTitle>
        <PolicyList>
          <PolicyItem>
            The Light Phone Inc., dba Light Phone (LIGHT) provides online
            services (&quot;websites&quot; or &quot;sites&quot;, as applicable)
            to you subject to the following Terms of Use (&quot;TOU&quot;). The
            TOU may be updated by us from time to time. Updates will be noted on
            the websites. If you visit or shop on the websites, you accept these
            terms. In addition, when using, registering and/or signing up for
            particular Light services, you and LIGHT shall be subject to any
            posted guidelines or rules applicable to such services. All such
            guidelines or rules are hereby incorporated by reference into the
            TOU.
          </PolicyItem>
        </PolicyList>
        {/* 2 */}
        <SectionTitle>2. PURPOSE OF WEBSITES</SectionTitle>
        <PolicyList>
          <PolicyItem>
            LIGHT currently provides the websites &quot;AS-IS&quot; for the
            purposes of a) distributing information about LIGHT products and
            services; b) providing an online method whereby individuals may use
            or place orders for such products and services; c) providing an
            online method whereby customers may manage their LIGHT accounts. We
            are constantly seeking to improve and enhance the websites and,
            unless explicitly stated otherwise, any new features that augment or
            enhance the current websites shall be subject to the TOU. You
            understand and agree that the websites are provided
            &quot;AS-IS&quot; and that LIGHT assumes no responsibility for the
            timeliness, deletion, misdelivery or failure to store any user
            communications or personalization settings. In order to use the
            websites, you must obtain access to the World Wide Web, either
            directly or through devices that access web-based content, and pay
            any service fees associated with such access. In addition, you must
            provide all equipment necessary to make such connection to the World
            Wide Web, including a computer and modem or other access device.
            Please be aware that to sign up for certain LIGHT services,
            including but not limited to telecommunications, you must be at
            least 18 years of age and must meet any stated sign-up criteria.
            LIGHT reserves the right at any time to modify or discontinue,
            temporarily or permanently, the websites (or any parts thereof) with
            or without notice. You agree that LIGHT shall not be liable to you
            or to any third party for any modification, suspension or
            discontinuance of the websites.
          </PolicyItem>
        </PolicyList>
        {/* 3 */}
        <SectionTitle>3. PRIVACY AND SECURITY POLICY</SectionTitle>
        <PolicyList>
          <PolicyItem>
            The information you provide is subject to our Privacy and Security
            Policy. For more information,
            <PolicyLink to="/support/privacy-policy">click here.</PolicyLink>
          </PolicyItem>
        </PolicyList>
        {/* 4 */}
        <SectionTitle>4. ELECTRONIC COMMUNICATIONS</SectionTitle>
        <PolicyList>
          <PolicyItem>
            When you visit the websites or send e-mails to us, you are
            communicating with us electronically. You consent to receive
            communications from LIGHT electronically. You agree that all
            agreements, notices, disclosures and other communications that LIGHT
            provides to you electronically satisfy any legal requirement that
            such communication be in writing.
          </PolicyItem>
        </PolicyList>
        {/* 5 */}
        <SectionTitle>5. PERMISSION TO CALL/TEXT</SectionTitle>
        <PolicyList>
          <PolicyItem>
            By signing up for Light Service through the Sites, you give express
            permission for us to contact you on your device.
          </PolicyItem>
        </PolicyList>
        {/* 6 */}
        <SectionTitle>6. ACCOUNT SECURITY</SectionTitle>
        <PolicyList>
          <PolicyItem>
            On selected LIGHT websites and, you will receive a password and
            account designation upon completing the website&apos;s registration
            process. You are responsible for maintaining the confidentiality of
            the password and account, and are fully responsible for all
            activities that occur under your password or account. You agree to
            (a) immediately notify LIGHT of any unauthorized use of your
            password or account or any other breach of security, and (b) ensure
            that you exit from your account at the end of each session. LIGHT
            cannot and will not be liable for any loss or damage arising from
            your failure to comply with this Section 6. Whenever LIGHT handles
            information that you enter on our Sites, regardless of where this
            occurs, we take steps to ensure that your information is treated
            securely and in accordance with the relevant Terms of Service and
            Privacy and Security Policy. Unfortunately, no data transmission
            over the Internet can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, LIGHT cannot
            ensure or warrant the security of any information you transmit to us
            or from our online products or services, and you do so at your own
            risk. Once we receive your transmission, we make our best effort to
            ensure its security on our systems.
          </PolicyItem>
        </PolicyList>
        {/* 7 */}
        <SectionTitle>7. USE OF WEBSITES</SectionTitle>
        <PolicyList>
          <PolicyItem>
            In consideration of your use of the websites, as applicable, you
            agree to use the websites as intended by LIGHT, and to refrain from
            any misuse of sites, services, and/or systems made available by
            LIGHT. Misuse of the websites includes but is not limited to any
            action described in 1-11 below, or that compromises LIGHT&apos;s
            Sites, services, systems and/or any information contained therein.
            Misuse of the websites will be prosecuted to the fullest extent of
            the law.
            <br />
            <br />
            You agree to not use the websites to:
            <br />
            1. upload, post, email or otherwise transmit any content, including
            but not limited to e-mail communication, information, data, text,
            software, music, sound, photographs, graphics, video, messages or
            other material (&quot;Content&quot;), that is unlawful, harmful,
            threatening, abusive, harassing, tortuous, defamatory, vulgar,
            obscene, libelous, invasive of another&apos;s privacy, hateful, or
            racially, ethnically or otherwise objectionable;
            <br />
            2. harm minors in any way;
            <br />
            3. impersonate any person or entity, including, but not limited to,
            a LIGHT employee, or falsely state or otherwise misrepresent your
            affiliation with a person or entity;
            <br />
            4. forge headers or otherwise manipulate identifiers in order to
            disguise the origin of any Content, including but not limited to
            e-mail commentary, transmitted through the websites;
            <br />
            5. upload, post, email or otherwise transmit any Content that you do
            not have a right to transmit under any law or under contractual or
            fiduciary relationships (such as inside information, proprietary and
            confidential information learned or disclosed as part of employment
            relationships or under nondisclosure agreements);
            <br />
            6. upload, post, email or otherwise transmit any Content that
            infringes any patent, trademark, trade secret, copyright or other
            proprietary rights (&quot;Rights&quot;) of any party;
            <br />
            7. upload, post, email or otherwise transmit any unsolicited or
            unauthorized advertising, promotional materials, &quot;junk
            mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot;
            &quot;pyramid schemes,&quot; or any other form of solicitation,
            except in those areas that are designated for such purpose;
            <br />
            8. upload, post, email or otherwise transmit any material that
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer software or hardware or telecommunications
            equipment;
            <br />
            9. interfere with or disrupt the websites or servers or networks
            connected to the websites, or disobey any requirements, procedures,
            policies or regulations of networks connected to the websites;
            <br />
            10. access, collect or store personal data about other users; or
            <br />
            11. engage in any conduct that in LIGHT&apos;s sole discretion
            restricts or inhibits any other user from using or enjoying the
            websites. You acknowledge that LIGHT and its designees shall have
            the right (but not the obligation) in their sole discretion to
            refuse or remove any Content that is available via the websites.
            Without limiting the foregoing, LIGHT and its designees shall have
            the right to remove any Content that violates the TOU or is
            otherwise objectionable. You agree that you must evaluate, and bear
            all risks associated with, the use of any Content, including any
            reliance on the accuracy, completeness, or usefulness of such
            Content. In this regard, you acknowledge that you may not rely on
            any Content created or linked to by LIGHT or submitted to LIGHT. You
            acknowledge and agree that LIGHT may preserve Content and may also
            disclose Content if required to do so by law or in the good faith
            belief that such preservation or disclosure is reasonably necessary
            to: (a) comply with legal process; (b) enforce the TOU; (c) respond
            to claims that any Content violates the rights of third parties; or
            (d) protect the rights, property, or personal safety of LIGHT, its
            users and the public.
            <br />
            <br />
            You understand that the technical processing and transmission of the
            websites, including your Content, may involve (a) transmissions over
            various networks; and (b) changes to conform and adapt to technical
            requirements of connecting networks or devices.
          </PolicyItem>
        </PolicyList>
        {/* 8 */}

        {/* 9 */}
        <SectionTitle>9. INDEMNITY</SectionTitle>
        <PolicyList>
          <PolicyItem>
            You agree to indemnify and hold LIGHT, and its affiliates, officers,
            agents, co-branders or other partners, representatives and
            employees, harmless from any claim or demand, including reasonable
            attorneys&apos; fees, made by any third party due to or arising out
            of Content you submit, post to or transmit through the websites,
            your use of the websites, your connection to the websites, your
            violation of the TOU, or your violation of any rights of another.
          </PolicyItem>
        </PolicyList>
        {/* 10 */}
        <SectionTitle>10. NO RESALE</SectionTitle>
        <PolicyList>
          <PolicyItem>
            The websites are provided for the use and enjoyment of visitors and
            registrants to LIGHT&apos;s websites. You agree not to reproduce,
            duplicate, copy, sell, resell or exploit for any commercial
            purposes, any portion of the websites, use of the websites, or
            access to the websites.
          </PolicyItem>
        </PolicyList>
        {/* 11 */}
        <SectionTitle>11. TERMINATION</SectionTitle>
        <PolicyList>
          <PolicyItem>
            You agree that LIGHT, in its sole discretion, may terminate your
            password or use of the websites, if LIGHT believes that you have
            violated or acted inconsistently with the letter or spirit of the
            TOU. LIGHT may also in its sole discretion and at any time
            discontinue providing the websites, or any parts thereof, with or
            without notice. You agree that any termination of your access to the
            websites under any provision of this TOU may be affected without
            prior notice. Further, you agree that LIGHT shall not be liable to
            you or any third party for any termination of your access to the
            websites.
          </PolicyItem>
        </PolicyList>
        {/* 12 */}
        <SectionTitle>12. LINKS, REFERENCES & RESOURCES</SectionTitle>
        <PolicyList>
          <PolicyItem>
            The websites may provide, or third parties may provide, references
            and/or links to other World Wide websites or resources. Because
            LIGHT has no control over such sites and resources, you acknowledge
            and agree that LIGHT is not responsible for the availability of such
            external sites or resources, and does not endorse or sponsor and is
            not responsible or liable for any TOU, Content, advertising,
            products, services, or other materials on or available from such
            sites or resources. You further acknowledge and agree that LIGHT
            shall not be responsible or liable, directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection
            with use of or reliance on any such Content, goods or services
            available on or through any such site or resource.
          </PolicyItem>
        </PolicyList>
        {/* 13 */}
        <SectionTitle>13. LIGHT&apos;S PROPRIETARY RIGHTS</SectionTitle>
        <PolicyList>
          <PolicyItem>
            All proprietary rights in the websites and any necessary software
            used in connection with the websites (&quot;Software&quot;) are
            owned or licensed for use by LIGHT. The websites are owned and
            operated by LIGHT and except as otherwise specified, LIGHT owns all
            materials appearing on the websites, including the text, site
            design, logos, graphics, and images, as well as the selection,
            assembly and arrangement thereof. You acknowledge and agree that the
            websites and Software contain proprietary and confidential
            information that is protected by applicable intellectual property
            and other laws. You further acknowledge and agree that Content
            contained in sponsor advertisements or sites or information
            presented to you through the websites or advertisers or merchants is
            protected by copyrights, trademarks, service marks, patents or other
            proprietary rights and laws. Except as expressly authorized by LIGHT
            or advertisers or merchants, you agree not to copy, modify, rent,
            lease, loan, sell, distribute, display, perform or create derivative
            works based on the websites or the Software, in whole or in part.
            LIGHT grants you a personal, non-transferable and non-exclusive
            right and license to use the object code of its Software; provided
            that you do not (and do not allow any third party to) copy, modify,
            display, distribute, create a derivative work of, reverse engineer,
            reverse assemble or otherwise attempt to discover any source code,
            sell, assign, sublicense, grant a security interest in or otherwise
            transfer any right in the Software. You agree not to modify the
            Software in any manner or form, or to use modified versions of the
            Software, including (without limitation) for the purpose of
            obtaining unauthorized access to the websites. You agree not to
            access the websites by any means other than through the interface
            that is provided by LIGHT for use in accessing the websites. LIGHT
            grants you permission to copy electronically and to print in hard
            copy portions of the Content for (1) personal use if you maintain
            all copyright notices, trademark legends and other proprietary
            rights notices, (2) using this websites as a personal shopping
            resource, (3) communicating with LIGHT about a LIGHT product or
            service, or (4) placing an order with LIGHT. Any other use of
            materials on this site, including reproduction for purposes other
            than permitted above, uploading, modification or distribution, is
            prohibited without LIGHT&apos;s prior written permission.
          </PolicyItem>
        </PolicyList>
        {/* 14 */}
        <SectionTitle>
          14. GENERAL DISCLAIMER OF WARRANTIES YOU EXPRESSLY UNDERSTAND AND
          AGREE THAT:
        </SectionTitle>

        {/* 15 */}
        <SectionTitle>
          15. YOUR USE OF THE WEBSITES IS AT YOUR SOLE RISK. THE WEBSITES ARE
          PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS.
          LIGHT EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </SectionTitle>

        {/* 16 */}
        <SectionTitle>
          16. LIGHT MAKES NO WARRANTY THAT (i) THE WEBSITES WILL MEET YOUR
          REQUIREMENTS, (ii) THE WEBSITES WILL BE UNINTERRUPTED, TIMELY, SECURE,
          OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
          THE WEBSITES WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY
          PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
          OBTAINED BY YOU THROUGH THE WEBSITES WILL MEET YOUR EXPECTATIONS, AND
          (V) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
        </SectionTitle>

        {/* 17 */}
        <SectionTitle>
          17. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
          THE WEBSITES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL
          BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS
          OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
        </SectionTitle>

        {/* 18 */}
        <SectionTitle>
          18. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM LIGHT OR THROUGH OR FROM THE WEBSITES SHALL CREATE ANY WARRANTY
          NOT EXPRESSLY STATED IN THE TOU.
        </SectionTitle>

        {/* 19 */}
        <SectionTitle>
          19. LIMITATION OF LIABILITY
          <br /> YOU EXPRESSLY UNDERSTAND AND AGREE THAT LIGHT SHALL NOT BE
          LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
          EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
          PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF LIGHT
          HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM:
          (i) THE USE OR THE INABILITY TO USE THE WEBSITES; (ii) THE COST OF
          PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS,
          DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES
          RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE WEBSITES;
          (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
          DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITES;
          OR (v) ANY OTHER MATTER RELATING TO THE WEBSITES.
        </SectionTitle>

        {/* 20 */}
        <SectionTitle>
          20. EXCLUSIONS AND LIMITATIONS SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME
          OF THE ABOVE LIMITATIONS OF SECTIONS 13 AND 14 MAY NOT APPLY TO YOU.
        </SectionTitle>

        {/* 21 */}
        <SectionTitle>21. TRADEMARK INFORMATION</SectionTitle>
        <PolicyList>
          <PolicyItem>
            LIGHT respects the intellectual property of others, and we ask our
            users to do the same. If you believe that your work has been copied
            in a way that constitutes copyright infringement, please contact us
            at
            <EmailLink href="mailto:support@thelightphone.com">
              support@thelightphone.com
            </EmailLink>
            .Light Phone and LIGHT logos and product and service names are
            trademarks of LIGHT (the &quot;Marks&quot;). Without LIGHT&apos;s
            prior permission, you agree not to display or use in any manner, the
            Marks.
          </PolicyItem>
        </PolicyList>
        {/* 22 */}
        <SectionTitle>22. GENERAL INFORMATION</SectionTitle>
        <PolicyList>
          <PolicyItem>
            The TOU, along with terms and conditions provided to registrants and
            customers of LIGHT&apos;s websites and services, constitute the
            entire agreement between you and LIGHT and govern your use of the
            websites, superseding any prior agreements between you and LIGHT.
            You also may be subject to additional terms and conditions that may
            apply when you use affiliate services, third-party content or
            third-party software. The TOU and the relationship between you and
            LIGHT shall be governed by the laws of the State of New York without
            regard to its conflict of law provisions. You understand and agree
            that, except for the right of either party to apply to a court of
            competent jurisdiction for a temporary restraining order or
            preliminary injunction to preserve the status quo or prevent
            irreparable harm pending the selection and confirmation of an
            arbitrator, any dispute arising under or relating to the websites or
            TOU shall be resolved through mediation and arbitration. You agree
            to first try to resolve the dispute informally with the help of a
            mutually agreed upon mediator. If the parties cannot agree on a
            mediator or fail to arrive at a mutually satisfactory solution
            through mediation within 10 days following the commencement of such
            mediation, the parties agree to submit their dispute to binding
            arbitration of a single arbitrator Brooklyn, NY according to the
            rules of the American Arbitration Association. You agree that
            regardless of any statute or law to the contrary, any claim or cause
            of action arising out of or related to use of the websites or the
            TOU must be filed within one (1) year after such claim or cause of
            action arose or be forever barred. The section titles in the TOU are
            for convenience only and have no legal or contractual effect.
          </PolicyItem>
        </PolicyList>
      </PolicyCard>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  color: ${colors.primary};
`;

const PolicyCard = styled.div`
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
`;

const PolicyList = styled.ul`
  padding-left: 20px;
  list-style: none;
  margin-bottom: 10px;
`;

const PolicyItem = styled.li`
  font-size: 14px;
  margin: 5px 0;
  display: inline;
  align-items: center;

  span {
    font-weight: bold;
    margin-right: 5px;
  }
`;
const PolicyLink = styled(Link)`
  font-size: 14px;
  color: ${colors.primary};
  margin-left: 5px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    color: ${colors.division};
  }
`;
const EmailLink = styled.a`
  font-size: 14px;
  color: ${colors.primary};
  margin-left: 5px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    color: ${colors.division};
  }
`;
export default SupportTerms;
