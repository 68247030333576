import React from "react";
import styled from "styled-components";
import {
  Title,
  LoadingIcon,
  HighlightedBox,
  Button,
} from "../../components/StyledComponent";

import { colors } from "../../components/Constants";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignUpService from "../../api/SignUpService";
import LoginService from "../../api/LoginService";
import { isDisabled } from "@testing-library/user-event/dist/utils";
const ChangePlan = () => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // const idToken = useSelector((state) => state.user.idToken);
  // const idToken = localStorage.getItem("idToken");
  const idToken = useSelector((state) => state.user.idToken);
  const authToken = localStorage.getItem("idToken");
  console.log("token", authToken);
  const {
    phoneNumber,
    startDate,
    endDate,
    planPrice,
    dataCapacity,
    planPfId,
    cnum,
  } = useSelector((state) => state.userInfo);
  console.log("Redux State in subscription Page:", {
    phoneNumber,
    startDate,
    endDate,
    planPrice,
    dataCapacity,
    planPfId,
    cnum,
  });
  //ANCHOR - Get plans
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const response = await SignUpService.checkPlans(authToken);
        if (response) {
          setPlans(response);
          console.log("plans:", response);
        } else {
          setErrorText("No plans available.");
        }
      } catch (error) {
        console.error("Error fetching plans", error);
        setErrorText("Failed to load plans. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [idToken]);

  const handlePlanClick = (plan) => {
    if (plan.PlanId === planPfId) return;

    setSelectedPlanId((prevSelected) =>
      prevSelected === plan.PlanId ? null : plan.PlanId
    );

    console.log(
      "Selected plan is",
      selectedPlanId === plan.PlanId ? "None (toggled off)" : plan.PlanId
    );
  };
  //TODO - need to change this !!!
  const handleConfirm = async () => {
    if (!selectedPlanId) {
      return;
    }
    setIsProcessing(true);

    const payload = {
      Cnum: cnum,
      PlanId: Number(selectedPlanId),
    };

    console.log("🚀 Sending ChangePlan Request:", payload);
    console.log("Sending request with:", {
      authToken,
      cnum,
      currentPlanPfId: planPfId,
      selectedPlanId: selectedPlanId,
    });
    try {
      const response = await LoginService.changePlan(
        authToken,
        cnum,
        selectedPlanId
      );

      console.log("Change Plan API Response:", response);

      if (response.IsSuccess) {
        alert("Plan changed request placed successfully ✅");
        navigate("/myplan");
      } else {
        console.error("API call successful but isSuccess is false:", response);
        alert("Plan change failed ❌");
      }
    } catch (error) {
      console.error("Error changing plan:", error);
      alert("Failed to change plan ❌");
    } finally {
      setIsProcessing(false);
    }
  };
  return (
    <div className="container">
      <Title>Select a new plan</Title>
      {loading ? (
        <LoadingContainer>
          <LoadingIcon style={{ fontSize: "34px" }} />
        </LoadingContainer>
      ) : plans.length > 0 ? (
        plans.map((plan, index) => {
          const isCurrentPlan = plan.PlanId === planPfId;
          const isSelected = plan.PlanId === selectedPlanId;
          console.log(`Plan ${plan.PlanId} isDisabled:`, isCurrentPlan);
          return (
            <PlanBox
              key={index}
              onClick={() => !isCurrentPlan && handlePlanClick(plan)}
              disabled={isCurrentPlan}
              isSelected={isSelected}
              style={{
                cursor: isCurrentPlan ? "not-allowed" : "pointer",
              }}
            >
              <div>
                <PlanPrice isDisabled={isCurrentPlan} isSelected={isSelected}>
                  ${plan.Fee}/month
                </PlanPrice>
                <br />
                <PlanDetails isDisabled={isCurrentPlan} isSelected={isSelected}>
                  {plan.Calling}
                  <br />
                  {plan.Message}
                  <br />
                  {plan.DataCapacity} of Data
                </PlanDetails>
                {isCurrentPlan ? <CurrentPlan>Current</CurrentPlan> : null}
              </div>
            </PlanBox>
          );
        })
      ) : (
        <p>{errorText}</p>
      )}

      <ButtonWrapper>
        <Button
          onClick={handleConfirm}
          disabled={!selectedPlanId || isProcessing}
          style={{
            border: !selectedPlanId ? "1px solid #d3d3d3" : "1ps solid black",
            borderRadius: "8px",
            backgroundColor: !selectedPlanId ? "#d3d3d3" : colors.primary,
            cursor: !selectedPlanId ? "not-allowed" : "pointer",
          }}
        >
          {isProcessing ? "Processing..." : "Confirm"}
        </Button>
      </ButtonWrapper>
    </div>
  );
};
const CurrentPlan = styled.div`
  z-index: 300;
  position: absolute;
  top: 40%;
  left: 35%;
  background-color: ${colors.error};
  opacity: 0.8;
  font-size: 30px;
  padding: 2%;
  color: ${colors.description};
  cursor: "not-allowed";
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const PlanBox = styled.div.attrs((props) => ({
  "data-disabled": props.isDisabled,
  "data-selected": props.isSelected,
}))`
  width: 100%;
  padding: 40px;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};

  background-color: ${(props) => (props.isDisabled ? "#f0f0f0" : "white")};
  color: ${(props) => (props.isDisabled ? "#a0a0a0" : "black")};
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  border: ${(props) =>
    props.isSelected ? "3px solid black" : "1px solid black"};
  position: relative;
`;

const PlanPrice = styled.span`
  font-size: 26px;
  font-weight: bold;
  color: ${(props) =>
    props.isDisabled ? "#a0a0a0" : props.isSelected ? colors.primary : "black"};
`;

const PlanDetails = styled.span`
  font-size: 14px;
  color: ${(props) => (props.isDisabled ? "#a0a0a0" : "black")};
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;
export default ChangePlan;
