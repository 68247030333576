import { sharedInstance } from "../api/axios";
const SignUpService = {
  //SECTION - register users -> send OTP
  signUp: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneRegistration",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during sign up:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - Validate OTP
  sendActivationCode: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneValidateOTP",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during send activation code:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - resend OTP
  resendActivationCode: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneResendOTP",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during resend activation code:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - get Plans
  checkPlans: async (idToken) => {
    try {
      const response = await sharedInstance.post(
        "/api/Activation/PrepaidPlans",
        null,
        {
          headers: {
            Authtoken: idToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during get prepaid plans",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - Get total prepaid amount
  getTotalPrepaid: async ({
    idToken,
    orderSimcard,
    planId,
    provinceCode,
    start,
  }) => {
    try {
      const response = await sharedInstance.get(
        "/api/Activation/GetTotalPrepaid",
        {
          params: {
            orderSimcard: orderSimcard,
            planId: planId,
            provinceCode: provinceCode,
            start: start,
          },
          headers: {
            Authtoken: idToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during fetching total prepaid amount:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  prepaidActivationPayment: async ({ authToken, ...paymentData }) => {
    try {
      const response = await sharedInstance.post(
        "/api/Activation/PrepaidActivationPayment",
        paymentData,
        {
          headers: {
            Authtoken: authToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during payment:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
};
export default SignUpService;
