import styled from "styled-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Title,
  LoadingIcon,
  Description,
  Input,
  Button,
  InputLabel,
  InputContainer,
  ErrorMessage,
} from "../../components/StyledComponent";
import { provinces } from "../../components/Constants";
import { useState } from "react";
import { LuRedoDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { StyledSelect } from "../../components/StyledComponent";
import { ArrowIcon } from "../../components/StyledComponent";
import {
  setProvince,
  setCity,
  setAddress,
  setPostalCode,
} from "../../api/UserInfoSlice";
import LoginService from "../../api/LoginService";
const ChangeAddress = () => {
  const idToken = useSelector((state) => state.user.idToken);
  const authToken = localStorage.getItem("idToken");
  const { province, city, address, postalCode } = useSelector(
    (state) => state.userInfo
  );

  const [nAddrSt, setNAddrSt] = useState(address || "");
  const [nAddrProv, setNAddrProv] = useState(province || "");
  const [nAddrCity, setNAddrCity] = useState(city || "");
  const [nAddrPCode, setNAddrPCode] = useState(postalCode || "");

  const [fieldErrors, setFieldErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  console.log(
    "changeAddress Redux store: ",
    authToken,
    province,
    city,
    address,
    postalCode
  );
  const handlePostalCodeValidation = (value) => {
    if (!value.trim()) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        postalCode: "Postal Code is required",
      }));
      return;
    }
    const isValidPostalCode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(
      value
    );
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      postalCode: isValidPostalCode
        ? ""
        : "Please enter the correct Postal Code",
    }));
  };
  //ANCHOR - Validation and HandleBLUR function
  const validateField = (field, value) => {
    switch (field) {
      case "province":
        return value.trim() ? "" : "Province is required";
      case "city":
        return value.trim() ? "" : "City is required";
      case "address":
        return value.trim() ? "" : "Address is required";
      default:
        return "";
    }
  };
  const handleBlur = (field, value) => {
    const error = validateField(field, value);

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  };

  //ANCHOR - NEXT BUTTON validation
  const handleNext = async () => {
    const errors = {};
    if (!nAddrProv.trim()) errors.province = "Province is required";
    if (!nAddrCity.trim()) errors.city = "City is required";
    if (!nAddrSt.trim()) errors.address = "Address is required";
    if (!nAddrPCode.trim()) errors.postalCode = "Postal Code is required";

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      console.log("Validation failed with errors: ", errors);
      return;
    }
    console.log("Authtoken being sent: ", idToken);
    setLoading(true);
    try {
      const response = await LoginService.changeAddress(
        authToken,
        nAddrSt,
        nAddrProv,
        nAddrCity,
        nAddrPCode
      );
      //REVIEW -
      console.log("Full API response from changeAddress: ", response);

      if (response.IsSuccess) {
        dispatch(setProvince(nAddrProv));
        dispatch(setAddress(nAddrSt));
        dispatch(setCity(nAddrCity));
        dispatch(setPostalCode(nAddrPCode));
        localStorage.setItem("province", nAddrProv);
        localStorage.setItem("city", nAddrCity);
        localStorage.setItem("postalCode", nAddrPCode);
        localStorage.setItem("address", nAddrSt);
        alert("✅ Address changed successfully");
        navigate("/setting");
      } else {
        console.error("Failed to submit the form", response);
        setError(
          "❌ Failed to change address. Please contact customer support."
        );
      }
    } catch (error) {
      console.error(" Error occurred while submitting the form", error);
      setError("❌ Failed to change address. Please contact customer support.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <Title>Update Address</Title>
      {/* Home Address */}
      <InputContainer>
        <Input
          placeholder=" "
          required
          value={nAddrSt}
          onChange={(e) => {
            setNAddrSt(e.target.value);
            setFieldErrors((prevErrors) => ({ ...prevErrors, address: "" }));
          }}
          onBlur={() => handleBlur("address", nAddrSt)}
          error={fieldErrors.address}
        />
        <InputLabel>Home Address</InputLabel>
        {fieldErrors.address && (
          <ErrorMessage>{fieldErrors.address}</ErrorMessage>
        )}
      </InputContainer>

      {/* Postal Code */}
      <InputContainer>
        <Input
          placeholder=" "
          required
          value={nAddrPCode}
          onChange={(e) => {
            setNAddrPCode(e.target.value);
            setFieldErrors((prevErrors) => ({ ...prevErrors, postalCode: "" }));
          }}
          onBlur={(e) => handlePostalCodeValidation(e.target.value)}
          error={fieldErrors.postalCode}
        />
        <InputLabel>Postal Code</InputLabel>
        {fieldErrors.postalCode && (
          <ErrorMessage>{fieldErrors.postalCode}</ErrorMessage>
        )}
      </InputContainer>
      {/* City */}
      <InputContainer>
        <Input
          placeholder=" "
          required
          value={nAddrCity}
          onChange={(e) => {
            setNAddrCity(e.target.value);
            setFieldErrors((prevErrors) => ({ ...prevErrors, city: "" }));
          }}
          onBlur={() => handleBlur("city", nAddrCity)}
          error={fieldErrors.city}
        />
        <InputLabel>City</InputLabel>
        {fieldErrors.city && <ErrorMessage>{fieldErrors.city}</ErrorMessage>}
      </InputContainer>
      {/* Province Selection */}
      <InputContainer>
        <StyledSelect
          value={nAddrProv}
          onChange={(e) => {
            setNAddrProv(e.target.value);
          }}
          required
          error={fieldErrors.province}
        >
          <option value="">Select a Province</option>
          {provinces.map((province) => (
            <option key={province.key} value={province.code}>
              {province.name}
            </option>
          ))}
        </StyledSelect>
        <ArrowIcon error={!!fieldErrors.province}>▼</ArrowIcon>
        <InputLabel>Province</InputLabel>
        {fieldErrors.province && (
          <ErrorMessage>{fieldErrors.province}</ErrorMessage>
        )}
      </InputContainer>
      <Button onClick={handleNext}>{loading ? <LoadingIcon /> : "Save"}</Button>
    </div>
  );
};

export default ChangeAddress;
