import styled from "styled-components";
import { Title } from "../../components/StyledComponent";
import { useSelector } from "react-redux";
import React from "react";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  LuVoicemail,
  LuTag,
  LuMessageSquare,
  LuSignal,
  LuPhone,
  LuCalendar,
} from "react-icons/lu";
import { RiSignalTowerFill } from "react-icons/ri";
import { colors } from "../../components/Constants";
const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const { planCalling, planOverRate, planCarrier, planPrice, dataCapacity } =
    useSelector((state) => state.userInfo);
  console.log(
    "from subscription plan page:",
    planCalling,
    planOverRate,
    planCarrier,
    planPrice,
    dataCapacity
  );
  return (
    <div className="container">
      <Title>Plan detail </Title>
      <PlanPrice>{planPrice}</PlanPrice>

      <SectionTitle>Allowances</SectionTitle>
      <Allowances>
        <Allowance>
          <AllowanceIcon>
            <LuSignal /> Data:
          </AllowanceIcon>
          <AllowanceValue>{dataCapacity} GB</AllowanceValue>
        </Allowance>
        <Allowance>
          <AllowanceIcon>
            <LuVoicemail /> Voice
          </AllowanceIcon>
          <AllowanceValue>{planCalling} min</AllowanceValue>
        </Allowance>
        <Allowance>
          <AllowanceIcon>
            <LuMessageSquare /> Messages
          </AllowanceIcon>
          <AllowanceValue>Unlimited</AllowanceValue>
        </Allowance>
      </Allowances>

      <SectionTitle>Plan Overview</SectionTitle>
      <PlanOverview>
        <OverviewItem>
          <OverviewLabel>
            <LuTag /> Price:
          </OverviewLabel>
          <OverviewValue>{planPrice.split("/")[0]}</OverviewValue>
        </OverviewItem>
        <OverviewItem>
          <OverviewLabel>
            <RiSignalTowerFill /> Carriers
          </OverviewLabel>
          <OverviewValue>{planCarrier}</OverviewValue>
        </OverviewItem>
        <OverviewItem>
          <OverviewLabel>
            <LuPhone /> Data Over Rate:
          </OverviewLabel>
          <OverviewValue>{planOverRate}</OverviewValue>
        </OverviewItem>
      </PlanOverview>
    </div>
  );
};

const PlanPrice = styled.p`
  font-size: 18px;
  text-align: center;
  color: ${colors.primary};
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  border-top: 1px solid ${colors.division};
  padding-bottom: 5px;
`;

const Allowances = styled.div`
  margin-bottom: 20px;
`;

const Allowance = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const AllowanceIcon = styled.span`
  min-width: 100px;
  margin-right: 20px;
  font-weight: bold;
  text-align: left;
`;

const AllowanceValue = styled.span`
  flex: 1;
  text-align: right;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.4;
  margin-left: 20px;
`;
const PlanOverview = styled.div`
  margin-bottom: 20px;
`;

const OverviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const OverviewLabel = styled.span`
  font-weight: bold;
`;

const OverviewValue = styled.span`
  color: ${colors.primary};
`;

export default SubscriptionPlan;
