import styled from "styled-components";
import { useState } from "react";
import React from "react";
import { LuPhone, LuRedo, LuMail, LuAlertOctagon } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  BoxContent,
  LoadingIcon,
  Title,
  Button,
} from "../../components/StyledComponent";
const SupportPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <Title>Privacy Policy</Title>
      <PolicyCard>
        <Header>
          <IconWrapper>
            <LuAlertOctagon size={28} />
          </IconWrapper>
        </Header>
        <Description>
          The Light Phone, Inc. and its brands, including Light Phone and Light
          Phone II (collectively, “LIGHT”), are committed to preserving your
          privacy and safeguarding your sensitive information. The following
          statements describe our general information-gathering and usage
          practices.
        </Description>

        <SectionTitle>General</SectionTitle>
        <PolicyList>
          <PolicyItem>
            1. LIGHT follows all state and federal guidelines protecting
            customer information.
          </PolicyItem>
          <PolicyItem>
            2. LIGHT follows all state and federal guidelines protecting
            customer information.
          </PolicyItem>
          <PolicyItem>
            3. Our sites may link to merchants and information sites. Each of
            these sites has its own privacy practices and any information you
            share with these sites is subject to their policies and practices
            and is independent of LIGHT.
          </PolicyItem>
          <PolicyItem>
            4. Our sites have security measures in place to protect the loss,
            misuse and alteration of the information under our control.
          </PolicyItem>
          <PolicyItem>
            5. This policy may be changed from time to time. Please refer to the
            effective date of the policy to determine whether changes have been
            introduced. To inquire about the content of such changes, or if you
            have any questions about this Privacy & Security Policy, the
            practices of LIGHT sites, or your dealings with our web sites,
            please contact: <br />
            Customer Service Team 19 Morris Ave. Brooklyn, NY 11206 1 (833)
            414-1033 support@thelightphone.com
          </PolicyItem>
        </PolicyList>

        {/* 3 */}
        <SectionTitle>INFORMATION COLLECTION AND USE</SectionTitle>
        <PolicyList>
          <PolicyItem>
            1. LIGHT uses your e-mail address and/or site registration
            information to offer you special benefits and communicate with you
            in the future. LIGHT may also share this information in order to
            provide you with service. Over time we will want to communicate with
            you via e-mail to inform you about new features and content on our
            sites, to request your vote on donations distribution (if eligible),
            and to make you aware of our special bonus offers and other
            services. You can opt out of receiving communications from us by
            following the opt-out instructions in our e-mails.
          </PolicyItem>
          <PolicyItem>
            2. LIGHT tracks, on a non-opt-out basis, information on which site
            pages are being visited and how they are being used.
          </PolicyItem>
          <PolicyItem>
            3. &quot;Cookies&quot; are bits of information that are stored by
            your browser on your hard drive. This technology allows you to save
            your password so you don&apos;t have to re-enter it each time you
            visit our site, and remembers you when you take action on our site.
            LIGHT uses &quot;cookies&quot;to identify return visitors. You can
            turn off the “cookie” function through most standard browsers.
          </PolicyItem>
          <PolicyItem>
            4. LIGHT may place small pieces of computer code on our site pages
            and in our emails to facilitate personalized transactions.
          </PolicyItem>
          <PolicyItem>
            5. Additional protections apply for certain information LIGHT
            collects and maintains about the telecommunications services you
            obtain from LIGHT and how you use those services. This information
            is categorized by the federal government as Customer Proprietary
            Network Information or CPNI. Specific laws govern LIGHT’s use and
            sharing of CPNI.
          </PolicyItem>
          <PolicyItem>
            6. To protect the security of your credit card information, we use
            SSL software, which encrypts provided and transmitted information.
          </PolicyItem>
          <PolicyItem>
            7. LIGHT retains customer name, address, phone number, email
            address, and product type information for historical purposes. LIGHT
            stores individually-identifiable customer billing data for three
            years unless longer storage is needed for tax, business, accounting,
            or legal purposes. LIGHT is committed to minimizing the data it
            collects and stores about how you use our telephone service. LIGHT
            does not receive, store, or assign precise handset location
            information, in the course of providing telephone service. LIGHT
            does not receive or store the content of customer communications
            sent using our services except customer communications directed to
            us for customer service purposes.
          </PolicyItem>
          <PolicyItem>
            8. Whenever LIGHT handles information that you enter on its sites,
            regardless of where this occurs, we take steps to ensure that your
            information is treated securely and in accordance with the relevant
            Terms of Service and this Privacy & Security Policy. Unfortunately,
            no data transmission over the Internet can be guaranteed to be 100%
            secure. As a result, while we strive to protect your personal
            information, LIGHT cannot ensure or warrant the security of any
            information you transmit to us or from our online products or
            services, and you do so at your own risk. Once we receive your
            transmission, we make our best effort to ensure its security on our
            systems.
          </PolicyItem>
        </PolicyList>

        {/* 2 */}
        <SectionTitle>INFORMATION SHARING AND DISCLOSURE</SectionTitle>
        <PolicyList>
          <PolicyItem>
            1. LIGHT does not sell, trade or release your e-mail address, phone
            number or any other personal information to outside third parties
            unless they are named partners on a given project or service
            partners subject to a non-disclosure agreement.
          </PolicyItem>
          <PolicyItem>
            2. If LIGHT enters into a merger, acquisition, sale of all or a
            portion of its assets, or other transfer of assets in the unlikely
            event of insolvency, bankruptcy or receivership, your customer
            information will also be transferred as one of the business assets
            of LIGHT.
          </PolicyItem>
          <PolicyItem>
            3. LIGHT and/or its third party providers may provide aggregate
            information such as the number of users who visit a particular
            linked site to other parties.
          </PolicyItem>
          <PolicyItem>
            4. When you visit a LIGHT website, information on which site pages
            you visit and how you are using them, as well as your IP address,
            referring URL, type of device, browser, operating system,
            geolocation (including city and country), cookie information,
            internet service provider, and any order information may be
            collected and disclosed to a third party for the purpose of
            improving our website and services.
          </PolicyItem>
          <PolicyItem>
            5. In the event of a judicial or governmental inquiry, LIGHT will
            release personally-identifiable information pursuant to a valid
            subpoena, summons, or court order. Unless specifically prohibited by
            court order or statute, LIGHT will notify you in writing of the
            request prior to releasing such information. LIGHT may release name
            and address information to a governmental entity if it in good faith
            believes that an emergency involving danger of death or serious
            physical injury to any person requires disclosure, without delay.
            <br /> <br /> <br />
            Effective March 9, 2022
          </PolicyItem>
        </PolicyList>
      </PolicyCard>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
`;

const PolicyCard = styled.div`
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const IconWrapper = styled.div`
  background: #fdecea;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: #d9534f;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
`;

const PolicyList = styled.ul`
  padding-left: 20px;
  list-style: none;
`;

const PolicyItem = styled.li`
  font-size: 14px;
  margin: 5px 0;
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    margin-right: 5px;
  }
`;
export default SupportPolicy;
