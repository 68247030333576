import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn } from "../../api/UserSlice";
import { setUserDetails } from "../../api/UserSlice";
import LoginService from "../../api/LoginService";
import { useState } from "react";
import {
  Title,
  Box,
  Button,
  BoxContent,
  Spinner,
} from "../../components/StyledComponent";
import { LuCalendar, LuSmartphone } from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import { colors } from "../../components/Constants";
import {
  setFirstName,
  setLastName,
  setEmail,
  setConfirmEmail,
  setProvince,
  setCity,
  setAddress,
  setPostalCode,
  setPhoneNumber,
  setCarrier,
  setAccountNumber,
  setImeiNumber,
  setPortIn,
  setSimCardNumber,
  storeUserInfoInLocalStorage,
  setPlanDetails,
  setSummaryDetails,
  setCreditCardType,
  setCreditCardLast4Digits,
  setCreditCardHolderName,
  setCreditCardExpirationMonth,
  setCreditCardExpirationYear,
  setStartDate,
  setEndDate,
  setPlanPrice,
  setDataCapacity,
  setPlanCarrier,
  setPlanOverRate,
  setPlanCalling,
  setPlanPfId,
  setCnum,
  setDataUsage,
} from "../../api/UserInfoSlice";
const MyPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const formattedPhoneNumber = phoneNumber
      .replace(/\D/g, "")
      .match(/^(\d{3})(\d{3})(\d{4})$/);
    if (formattedPhoneNumber) {
      return `+1 (${formattedPhoneNumber[1]}) ${formattedPhoneNumber[2]}-${formattedPhoneNumber[3]}`;
    }
    return phoneNumber;
  };
  const handleClick = () => {
    navigate("/plans");
  };

  useEffect(() => {
    const fetchClientPlan = async () => {
      try {
        const idToken = localStorage.getItem("idToken");
        if (!idToken) {
          navigate("/login");
          throw new Error("User is not authenticated");
        }

        const [response_plan, response_profile] = await Promise.all([
          LoginService.getProfile(idToken),
          LoginService.getAccountInfo(idToken),
        ]);
        if (!response_plan || response_plan.length === 0) {
          console.warn("No plan data received.");
          return;
        }
        if (!response_profile || response_profile.length === 0) {
          console.warn("No profile data received.");
          return;
        }
        // save local
        setUserPlan(response_plan[0]);
        setUserProfile(response_profile[0]);
        //REVIEW - console.log check for plan
        console.log("response for getProfile(plan)", response_plan[0]);
        //SECTION - dispatch for address, city, province and zipcode
        //personal info:
        dispatch(setFirstName(response_plan[0].FirstName));
        dispatch(setLastName(response_plan[0].LastName));
        dispatch(setEmail(response_plan[0].Email));
        dispatch(setCnum(response_plan[0].AccountNumber));
        //address:
        dispatch(setAddress(response_plan[0].StreetAddress));
        dispatch(setCity(response_plan[0].City));
        dispatch(setProvince(response_plan[0].Province));
        dispatch(setPostalCode(response_plan[0].ZipCode));
        //payment:
        dispatch(setCreditCardType(response_plan[0].CreditCardType));
        dispatch(
          setCreditCardLast4Digits(response_plan[0].CreditCardLast4Digits)
        );
        dispatch(
          setCreditCardHolderName(response_plan[0].CreditCardHolderName)
        );
        dispatch(
          setCreditCardExpirationMonth(
            response_plan[0].CreditCardExpirationMonth
          )
        );
        dispatch(
          setCreditCardExpirationYear(response_plan[0].CreditCardExpirationYear)
        );
        //plan:
        dispatch(setPhoneNumber(response_plan[0].PhoneNumber));
        dispatch(setStartDate(response_profile[0].StartDate));
        dispatch(setEndDate(response_profile[0].EndDate));
        dispatch(setPlanPrice(response_profile[0].CurrentPlan));
        dispatch(setDataCapacity(response_profile[0].DataCapacity));
        dispatch(setDataUsage(response_profile[0].DataUsage));
        dispatch(setPlanCarrier(response_profile[0].Carrier));
        dispatch(setPlanOverRate(response_profile[0].DataOverage));
        dispatch(setPlanCalling(response_profile[0].Calling));
        dispatch(setPlanPfId(response_profile[0].CurrentPlan_pfId));
        //REVIEW - console.log check for profile
        console.log(
          "response for getAccountInfo(profile)",
          response_profile[0]
        );
      } catch (error) {
        console.error("Failed to fetch user profile", error);
        setErrorMessage(
          error.response?.data?.message || "Unable to fetch user profile"
        );
      }
    };
    fetchClientPlan();
  }, [navigate]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(setAddress(userProfile.StreetAddress));
  //     dispatch(setCity(userProfile.City));
  //     dispatch(setProvince(userProfile.Province));
  //     dispatch(setPostalCode(userProfile.ZipCode));
  //   }, 0);
  // }, [userProfile, dispatch]);
  return (
    <div className="container">
      {userPlan && userProfile ? (
        <div>
          <Header style={{ marginTop: "50px" }}>
            <Title>My Plans</Title>
            <SettingsButton onClick={() => navigate("/setting")}>
              Account setting
            </SettingsButton>
          </Header>

          <Box>
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>
              Light Plan
            </span>
            <span style={{ fontSize: "24px" }}>{userProfile.CurrentPlan}</span>
            <span style={{ fontSize: "14px", color: colors.description }}>
              {userProfile.Calling} minutes Calls & Unlimited Messages with{" "}
              {userProfile.DataCapacityName} of Data
            </span>
          </Box>

          <BoxContent>
            <UsageInfo>
              <UsageUsed>
                <span style={{ fontSize: "20px" }}>
                  {userProfile.DataUsage}
                </span>
                <span>Used</span>
              </UsageUsed>
              <UsageTotal>{userProfile.DataCapacityName} total</UsageTotal>
            </UsageInfo>

            <UsageBar>
              <UsageProgress style={{ width: "40%" }} />
            </UsageBar>

            <span
              style={{
                fontSize: "9px",
                color: colors.description,
                margin: "5px",
                textAlign: "left",
              }}
            >
              Data usage data can be delayed by up to 48 hours
            </span>

            <DeviceInfo>
              <DeviceRow>
                <DeviceIcon>
                  <LuSmartphone />
                </DeviceIcon>
                <DeviceText>
                  <span style={{ color: colors.description }}>
                    Light Phone II
                  </span>
                  <span>{formatPhoneNumber(userPlan.PhoneNumber)}</span>
                </DeviceText>
              </DeviceRow>
              <DeviceRow>
                <DeviceIcon>
                  <LuCalendar />
                </DeviceIcon>
                <DeviceText>
                  <span style={{ color: colors.description }}>
                    Next Renewal
                  </span>
                  <span>{userProfile.EndDate.split("T")[0]}</span>
                </DeviceText>
              </DeviceRow>
              <SubscriptionDetails onClick={() => navigate("/subscription")}>
                <span>Subscription Details</span>
                <IoIosArrowForward />
              </SubscriptionDetails>
            </DeviceInfo>
          </BoxContent>

          {/* <Button onClick={handleClick}>Add a Plan</Button> */}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

// Styled Components
const UsageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  gap: 8px;
`;

const UsageUsed = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  font-size: 12px;
  font-weight: bold;
`;

const UsageTotal = styled.div`
  font-size: 12px;
  color: ${colors.primary};
  font-weight: bold;
`;

const UsageBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.littleBackground};
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
`;

const UsageProgress = styled.div`
  height: 100%;
  background-color: ${colors.primary};
`;

const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const DeviceRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 10px;
  /* border-top: 1px solid ${colors.division}; */
`;

const DeviceIcon = styled.span`
  font-size: 16px;
  color: ${colors.primary};
`;

const DeviceText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
`;

const SubscriptionDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  padding-top: 20px;
  font-weight: bold;
  /* border-top: 1px solid ${colors.division}; */
`;
const SettingsButton = styled.button`
  font-size: 11px;
  font-weight: bold;
  padding: 7px 10px;
  margin-bottom: 12px;
  background-color: ${colors.littleBackground};
  border-radius: 20px;
  border: none;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default MyPlan;
