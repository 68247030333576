import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import LoginService from "../../api/LoginService";
import {
  Title,
  LoadingIcon,
  Input,
  Button,
  InputLabel,
  InputContainer,
} from "../../components/StyledComponent";
import { setFirstName, setLastName } from "../../api/UserInfoSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ChangeName = () => {
  const { firstName, lastName } = useSelector((state) => state.userInfo);
  const idToken = useSelector((state) => state.user.idToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nfName, setNfName] = useState(firstName || "");
  const [nlName, setNlName] = useState(lastName || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  console.log("from Redux store: ", firstName, lastName, idToken);

  const handleSave = async () => {
    if (!nfName || !nlName) {
      setError("First name and last name are required.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await LoginService.changeName(idToken, nfName, nlName);
      if (response.IsSuccess) {
        setError("");
        dispatch(setFirstName(nfName));
        dispatch(setLastName(nlName));
        localStorage.setItem("firstName", nfName);
        localStorage.setItem("lastName", nlName);
        alert("✅ Name changed successfully");
        navigate("/setting");
      } else {
        setError("❌ Failed to change name. Please contact customer support.");
      }
    } catch (err) {
      setError("Failed to update name. Please try again.");
      console.error("Change name error:", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <Title>Update Full Name</Title>
      <InputContainer>
        <Input value={nfName} onChange={(e) => setNfName(e.target.value)} />
        <InputLabel>First Name</InputLabel>
      </InputContainer>
      <InputContainer>
        <Input value={nlName} onChange={(e) => setNlName(e.target.value)} />
        <InputLabel>Last Name</InputLabel>
      </InputContainer>
      {error && (
        <p style={{ color: "red", fontSize: "10px", fontWeight: "bold" }}>
          {error}
        </p>
      )}
      <Button onClick={handleSave} disabled={loading}>
        {loading ? <LoadingIcon /> : "Save"}
      </Button>
    </div>
  );
};

export default ChangeName;
