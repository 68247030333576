import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "../../components/StyledComponent";
import {
  BsCalendar,
  BsArrowRepeat,
  BsFillInfoCircleFill,
} from "react-icons/bs";
import { colors } from "../../components/Constants";
const SubscriptionSetting = () => {
  const navigate = useNavigate();

  const handleChangePlan = () => {
    //TODO -
    navigate("/subscription/change-plan");
  };

  const handleCancelSubscription = () => {
    //TODO -
    navigate("/subscription/cancelSubscription");
  };
  const { startDate, endDate } = useSelector((state) => state.userInfo);

  return (
    <div className="container">
      <Header>
        <Title>Subscription Settings</Title>
        <CancelButton onClick={handleCancelSubscription}>
          Cancel Subscription
        </CancelButton>
      </Header>

      <Section>
        <DetailItem>
          <Icon>
            <BsCalendar />
          </Icon>
          <DetailText>
            <span>Subscription Started</span>
            <DateText>{startDate.split("T")[0]}</DateText>
          </DetailText>
        </DetailItem>

        <DetailItem>
          <Icon>
            <BsArrowRepeat />
          </Icon>
          <DetailText>
            <span>Next Renewal</span>
            <DateText>{endDate.split("T")[0]}</DateText>
          </DetailText>
        </DetailItem>
      </Section>

      <NoticeBox>
        <BsFillInfoCircleFill style={{ fontSize: "20px" }} />
        <NoticeText>
          Your subscription will be charged to your selected payment method
          every month.
        </NoticeText>
      </NoticeBox>

      <ButtonWrapper>
        <Button onClick={handleChangePlan}>Change Plan</Button>
      </ButtonWrapper>
    </div>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 22px;
  font-weight: bold;
`;

const Section = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${colors.division};
  padding-top: 15px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.division};
`;

const Icon = styled.div`
  font-size: 18px;
  margin-right: 15px;
  color: #888;
`;

const DetailText = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateText = styled.span`
  font-size: 14px;
`;

const NoticeBox = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.division};
  margin-top: 20px;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 14px;
  color: ${colors.description};
`;

const NoticeText = styled.p`
  margin-left: 8px;
  padding: 15px 8px 0px;
  line-height: 1.2;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const CancelButton = styled.button`
  font-size: 10px;
  font-weight: bold;
  padding: 7px 10px;
  margin-bottom: 12px;
  background-color: ${colors.littleBackground};
  border-radius: 20px;
  border: none;
  cursor: pointer;
`;

export default SubscriptionSetting;
