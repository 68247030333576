import styled from "styled-components";
import React from "react";
import {
  Title,
  InputContainer,
  Input,
  InputLabel,
  Button,
  ErrorMessage,
} from "./StyledComponent";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SignUpService from "../api/SignUpService";
const Payment = () => {
  const navigate = useNavigate();
  const [cardNumber, setCardNumber] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [errorText, setErrorText] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const { planDetails } = useSelector((state) => state.userInfo);
  const location = useLocation();
  const { paId } = location.state || {};
  const idToken = useSelector((state) => state.user.idToken);
  console.log("paId:", paId);
  console.log("authToken:", idToken);
  //ANCHOR - handle input validation:
  const handleExpirationChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length >= 3) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    setExpirationDate(value);
  };
  const validateInputs = () => {
    const errors = {};

    if (![15, 16].includes(cardNumber.length) || !/^\d+$/.test(cardNumber)) {
      errors.cardNumber = "Invalid card number.";
    }
    if (!nameOnCard.trim()) {
      errors.nameOnCard = "Cardholder name is required.";
    }
    if (!/^\d{2}\/\d{2}$/.test(expirationDate)) {
      errors.expirationDate = "Invalid expiration date.";
    }
    if (![3, 4].includes(cvv.length) || !/^\d+$/.test(cvv)) {
      errors.cvv = "Invalid CVV.";
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };
  //ANCHOR - handle onBlur
  const handleBlur = (field, value) => {
    const errors = { ...fieldErrors };

    if (field === "expirationDate") {
      handleExpirationChange({ target: { value } });

      if (!/^\d{2}\/\d{2}$/.test(value)) {
        errors.expirationDate = "Invalid expiration date.";
      } else {
        delete errors.expirationDate;
      }
    } else {
      switch (field) {
        case "cardNumber":
          if (![15, 16].includes(value.length) || !/^\d+$/.test(value))
            errors.cardNumber = "Invalid card number.";
          else delete errors.cardNumber;
          break;
        case "nameOnCard":
          if (!value.trim()) errors.nameOnCard = "Cardholder name is required.";
          else delete errors.nameOnCard;
          break;
        case "cvv":
          if (![3, 4].includes(value.length) || !/^\d+$/.test(value))
            errors.cvv = "Invalid CVV.";
          else delete errors.cvv;
          break;
        default:
          break;
      }
    }

    setFieldErrors(errors);
  };

  //ANCHOR - PARSE expiration date
  const [expiry_month, expiry_year] = expirationDate.split("/");
  const expirationMonth = parseInt(expiry_month, 10);
  const formattedExpirationYear = parseInt(expiry_year, 10);

  const requestData = {
    authToken: idToken,
    paId: Number(paId),
    CreditCardType: "",
    CreditCardNumber: cardNumber,
    CreditCardName: nameOnCard,
    CCV: cvv,
    ExpirationMonth: expirationMonth,
    ExpirationYear: formattedExpirationYear,
    PaymentAmt: parseFloat(planDetails.total).toFixed(2),
    AutoPayment: true,
    currency: planDetails.currency,
    AutoTopUp: false,
    epid: 0,
  };
  console.log("All request data:", requestData);

  const handleNext = async () => {
    if (!validateInputs()) {
      return;
    }

    try {
      const response = await SignUpService.prepaidActivationPayment(
        requestData
      );

      if (response) {
        console.log("Payment API Response:", response);
        navigate("/summary");
      } else {
        console.error("Payment failed:", response);
        setErrorText("Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred during payment:", error);
      setErrorText("An Error occurred. Please try again.");
    }
  };

  return (
    <div className="container">
      <Title>Payment</Title>
      {errorText && <p style={{ color: "red" }}>{errorText}</p>}

      {/* Credit Card Number */}
      <InputContainer>
        <Input
          type="text"
          placeholder=" "
          maxLength="16"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          onBlur={(e) => handleBlur("cardNumber", e.target.value)}
          error={fieldErrors.cardNumber}
        />
        <InputLabel>Credit Card Number</InputLabel>
        {fieldErrors.cardNumber && (
          <ErrorMessage>{fieldErrors.cardNumber}</ErrorMessage>
        )}
      </InputContainer>

      {/* Cardholder Name */}
      <InputContainer>
        <Input
          type="text"
          placeholder=" "
          value={nameOnCard}
          onChange={(e) => setNameOnCard(e.target.value)}
          onBlur={(e) => handleBlur("nameOnCard", e.target.value)}
          error={fieldErrors.nameOnCard}
        />
        <InputLabel>Cardholder Name</InputLabel>
        {fieldErrors.nameOnCard && (
          <ErrorMessage>{fieldErrors.nameOnCard}</ErrorMessage>
        )}
      </InputContainer>

      {/* Expiration Date */}
      <InputContainer>
        <Input
          type="text"
          placeholder="MM/YY"
          maxLength="5"
          value={expirationDate}
          onChange={handleExpirationChange}
          onBlur={(e) => handleBlur("expirationDate", e.target.value)}
          error={fieldErrors.expirationDate}
        />
        <InputLabel>Expiration Date (MM/YY)</InputLabel>
        {fieldErrors.expirationDate && (
          <ErrorMessage>{fieldErrors.expirationDate}</ErrorMessage>
        )}
      </InputContainer>

      {/* CVV */}
      <InputContainer>
        <Input
          type="text"
          placeholder=" "
          maxLength="3"
          value={cvv}
          onChange={(e) => setCVV(e.target.value)}
          onBlur={(e) => handleBlur("cvv", e.target.value)}
          error={fieldErrors.cvv}
        />
        <InputLabel>CVV</InputLabel>
        {fieldErrors.cvv && <ErrorMessage>{fieldErrors.cvv}</ErrorMessage>}
      </InputContainer>

      <Button onClick={handleNext}>Save and Continue</Button>
    </div>
  );
};

export default Payment;
