import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn } from "../../api/UserSlice";
import { setUserDetails } from "../../api/UserSlice";
import LoginService from "../../api/LoginService";
import { useState } from "react";
import {
  Title,
  Box,
  Button,
  BoxContent,
  Spinner,
} from "../../components/StyledComponent";
import { LuCalendar, LuSmartphone } from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import { colors } from "../../components/Constants";
import {
  setFirstName,
  setLastName,
  setEmail,
  setConfirmEmail,
  setProvince,
  setCity,
  setAddress,
  setPostalCode,
  setPhoneNumber,
  setCarrier,
  setAccountNumber,
  setImeiNumber,
  setPortIn,
  setSimCardNumber,
  storeUserInfoInLocalStorage,
  setPlanDetails,
  setSummaryDetails,
  setCreditCardType,
  setCreditCardLast4Digits,
  setCreditCardHolderName,
  setCreditCardExpirationMonth,
  setCreditCardExpirationYear,
} from "../../api/UserInfoSlice";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const idToken = useSelector((state) => state.user.idToken);
  // const paymentHistoryData = [
  //   {
  //     Cnum: "9825016660002",
  //     PaymentCategory: "ONLINE",
  //     PaymentSubCategory: "PAYMENT BY Online Prepaid Activation",
  //     CardHolder: "satnam S",
  //     CardNumber: "4242",
  //     Currency: "CAD",
  //     Debit: "0.00",
  //     Credit: "-42.00",
  //     Balance: "0.00",
  //     TransactionDate: "2025-01-09",
  //   },
  //   {
  //     Cnum: "9825016660002",
  //     PaymentCategory: "GST/HST",
  //     PaymentSubCategory: "5%",
  //     CardHolder: "satnam S",
  //     CardNumber: "",
  //     Currency: "CAD",
  //     Debit: "2.00",
  //     Credit: "0.00",
  //     Balance: "42.00",
  //     TransactionDate: "2025-01-09",
  //   },
  //   {
  //     Cnum: "9825016660002",
  //     PaymentCategory: "MONTHLY FEE",
  //     PaymentSubCategory: "MONTHLY FEE",
  //     CardHolder: "satnam S",
  //     CardNumber: "",
  //     Currency: "CAD",
  //     Debit: "40.00",
  //     Credit: "0.00",
  //     Balance: "40.00",
  //     TransactionDate: "2025-01-09",
  //   },
  // ];
  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const paymentHistoryData = await LoginService.getPaymentHistory({
          idToken,
        });
        if (paymentHistoryData) {
          console.log("from payment history ⏳", paymentHistoryData);
          setPaymentHistoryData(paymentHistoryData);
        }
      } catch {
        console.error("Failed to fetch payment history:");
        setErrorMessage("Failed to load payment history. Please try again.");
      }
    };
    fetchPaymentHistory();
  }, []);
  return (
    <div className="container">
      {paymentHistoryData ? (
        <>
          <Title>Payment History</Title>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <HistoryList>
            {paymentHistoryData.map((payment, index) => (
              <Card key={index}>
                <Header>
                  <Category>{payment.PaymentCategory}</Category>
                  <Date>{payment.TransactionDate}</Date>
                </Header>
                <Value>{payment.PaymentSubCategory}</Value>

                <Info>
                  <Label>Card Holder:</Label>
                  <Value>{payment.CardHolder}</Value>
                </Info>
                <Info>
                  <Label>Card Number:</Label>
                  <Value>
                    {payment.CardNumber ? `****${payment.CardNumber}` : "N/A"}
                  </Value>
                </Info>
                <Info>
                  <Label>Debit:</Label>
                  <Amount className="debit">
                    {payment.Debit} {payment.Currency}
                  </Amount>
                </Info>
                <Info>
                  <Label>Credit:</Label>
                  <Amount className="credit">
                    {payment.Credit} {payment.Currency}
                  </Amount>
                </Info>
                <Info>
                  <Label>Balance:</Label>
                  <Balance>
                    {payment.Balance} {payment.Currency}
                  </Balance>
                </Info>
              </Card>
            ))}
          </HistoryList>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Card = styled.div`
  background: #fafafa;
  border-radius: 10px;
  padding: 15px;
  color: ${colors.primary};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
`;

const Category = styled.span`
  font-weight: bold;
`;

const Date = styled.span`
  font-size: 14px;
  color: ${colors.description};
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: ${colors.description};
`;

const Value = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.description};
`;

const Amount = styled.span`
  color: ${colors.description};
`;

const Balance = styled.span`
  color: ${colors.description};
`;
export default PaymentHistory;
