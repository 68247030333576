import styled from "styled-components";
import { colors } from "./Constants";
import { LuRedoDot } from "react-icons/lu";
import keyframes from "styled-components";
export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
`;
export const SubTitle = styled.h2`
  font-size: 18px;
  color: ${colors.background};
  font-weight: bold;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 15px;
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 22px;
  color: rgba(211, 213, 216, 0.9);
  font-size: 22px;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  padding: 25px 20px 6px 32px;
  border: 2px solid
    ${({ error }) => (error ? colors.error : colors.description)};
  border-radius: 5px;
  font-size: 14px;
  background-color: ${({ error }) => (error ? "#ffeef0" : "white")};
  box-shadow: none;

  &:focus {
    border: 2px solid ${({ error }) => (error ? colors.error : "black")};
    outline: none;
  }
`;

export const InputLabel = styled.label`
  position: absolute;
  top: 8px;
  left: 12px;
  font-size: 11px;
  color: ${colors.description};
  transition: 0.2s ease all;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 10px;
  color: ${colors.error};
  background-color: transparent;
  padding: 0 5px;
  font-weight: bold;
`;

export const Box = styled.div`
  width: 100%;
  /* height: 25%; */
  padding: 40px;
  border: 2px solid ${colors.background};
  border-radius: 5px;
  font-size: 16px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
  & span {
    color: ${colors.background};
  }
`;
export const BoxContent = styled.div`
  width: 100%;
  padding: 20px;
  border: 2px solid ${colors.background};
  border-radius: 5px;
  font-size: 14px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
  & span {
    color: ${colors.primary};
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${colors.littleBackground};
  border-radius: 5px;
  font-size: 16px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
`;
export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 400px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px 16px;
  background-color: #1a1a1a;
  color: white;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

export const Description = styled.p`
  font-size: 15px;
  color: ${colors.description};
`;

export const BreakPoint = styled.div`
  height: 20px;
  width: 100%;
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 25px 20px 6px;
  font-size: 14px;
  border: 2px solid
    ${({ error }) => (error ? colors.error : colors.description)};
  border-radius: 5px;
  background-color: ${({ error }) => (error ? "#ffeef0" : "white")};
  box-shadow: none;
  appearance: none;

  &:focus {
    border: 2px solid black;
    outline: none;
  }
`;

export const ArrowIcon = styled.span`
  position: absolute;
  right: 20px;
  top: 30px;
  pointer-events: none;
  color: ${({ error }) => (error ? colors.error : colors.description)};
`;

export const LoadingIcon = styled(LuRedoDot)`
  animation: rotate 1s linear infinite;
  font-size: 1.5rem;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const EyeIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
  font-size: 18px;
  color: ${colors.primary};

  &:hover {
    color: ${colors.description};
  }
`;
export const ResendLink = styled.span`
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;
export const TextItem = styled.p`
  font-size: 14px;
  color: ${colors.description};
  margin: 5px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    color: ${colors.description};
  }
`;
export const HighlightedBox = styled.div`
  width: 100%;
  padding: 40px;
  border: 2px solid ${colors.description};
  border-radius: 5px;
  font-size: 16px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;

  transition: all 0.3s ease;

  :hover {
    color: ${colors.primary};
    cursor: pointer;
  }
  div {
    color: ${colors.background};
  }
`;
export const Spinner = styled.div`
  position: relative;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 85px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: linear-gradient(black 50px, transparent 0),
    linear-gradient(black 50px, transparent 0),
    linear-gradient(black 50px, transparent 0),
    linear-gradient(black 50px, transparent 0),
    linear-gradient(black 50px, transparent 0),
    linear-gradient(black 50px, transparent 0);
  background-position: 0px center, 15px center, 30px center, 45px center,
    60px center, 75px center, 90px center;
  animation: rikSpikeRoll 0.65s linear infinite alternate;

  @keyframes rikSpikeRoll {
    0% {
      background-size: 10px 3px;
    }
    16% {
      background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px,
        10px 3px;
    }
    33% {
      background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px,
        10px 3px;
    }
    50% {
      background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px,
        10px 3px;
    }
    66% {
      background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px,
        10px 3px;
    }
    83% {
      background-size: 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px,
        10px 3px;
    }
    100% {
      background-size: 10px 3px, 10px 3px, 10px 3px, 10px 10px, 10px 30px,
        10px 50px;
    }
  }
`;
