import React from "react";
import styled from "styled-components";
import { Title, SubTitle, TextItem } from "./StyledComponent";
import { Description } from "./StyledComponent";
import {
  InputContainer,
  Input,
  InputLabel,
  SectionContainer,
  HighlightedBox,
  Box,
} from "./StyledComponent";
import { Button } from "./StyledComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sharedInstance } from "../api/axios";
import LoginService from "../api/LoginService";
import { useLocation } from "react-router-dom";
import PlansDetail from "./PlansDetail";
import { useState } from "react";
import { useEffect } from "react";
const Checkout = () => {
  const navigate = useNavigate();
  const [clientInfo, setClientInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const {
    firstName,
    lastName,
    email,
    confirmEmail,
    province,
    city,
    address,
    postalCode,
    phoneNumber,
    carrier,
    accountNumber,
    imeiNumber,
    portIn,
    simCardNumber,
    planDetails,
    summaryDetails,
  } = useSelector((state) => state.userInfo);
  const idToken = useSelector((state) => state.user.idToken);
  // const location = useLocation();
  // const plans = location.state?.plan || [];
  // console.log("plans:", plans);
  // console.log("simcardNO", simCardNumber);
  //ANCHOR -  Fetch client info when the component mounts
  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        // console.log("Using idToken in fetchClientInfo:", idToken);
        const response = await LoginService.getBasicInfo(idToken);
        if (response) {
          setClientInfo({
            firstName: response.Firstname,
            lastName: response.LastName,
            email: response.Email,
          });
          // console.log("personalinfo:", clientInfo.firstName);
        }
      } catch (error) {
        console.error("Error fetching client info:", error);
      }
    };

    fetchClientInfo();
  }, []);
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    }).format(new Date(date));
  };
  const getFormatDate = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };
  const getEndDate = () => {
    const today = new Date();
    const endDate = new Date(today.setDate(today.getDate() + 30));
    return endDate.toISOString().split("T")[0];
  };
  const EndDate = getEndDate();
  const fmtDate = formatDate(new Date());
  console.log("plansDetails: ", planDetails);
  console.log("summaryDetails: ", summaryDetails);
  console.log(
    "personalinfo: ",
    clientInfo.firstName,
    clientInfo.lastName,
    clientInfo.email,
    province,
    city,
    postalCode,
    address,
    summaryDetails.planId,
    EndDate,
    getFormatDate(new Date())
  );
  //ANCHOR - handle next button
  const handleNext = async () => {
    try {
      const response = await LoginService.postActivationInfoPrepaid({
        idToken,
        simcardOrder: false,
        simcard_no: simCardNumber,
        startDate: getFormatDate(new Date()),
        endDate: getEndDate(),
        planId: summaryDetails.planId,
        firstName: clientInfo.firstName,
        lastName: clientInfo.lastName,
        email: clientInfo.email,
        serviceType: "M",
        portin_carrier: "",
        portin_accountNo: "",
        portin_phoneNo: "",
        portin_other: "",
        countryId: 42,
        streetaddress: address,
        province: province,
        city: city,
        postal: postalCode,
        currency: planDetails.currency,
        simcard_fee: planDetails.simcard_amt,
        prorate_fee: planDetails.prorate_Amt,
        charge_duration: planDetails.charge_Duration,
        plan_fee: planDetails.plan_Amt,
        gst_rate: planDetails.gst_rate,
        pst_rate: planDetails.pst_rate,
        gst_amt: planDetails.gst_Amt,
        pst_amt: planDetails.pst_Amt,
        subtotal: planDetails.subtotal,
        promocode: "",
        promocredit: planDetails.promo_Amt,
        total: planDetails.total,
        bizId: 0,
        sfID: 0,
        referral_cnum: "",
        consent_cem: true,
        shipping_contact_number: "",
      });

      if (response) {
        console.log("checkout response data", response);
        navigate("/payment", {
          state: {
            paId: response,
          },
        });
      } else {
        console.error("failed:", response);
      }
    } catch (error) {
      console.error("Error occurred during activation:", error);
    }
  };
  return (
    <div className="container">
      <Title>Checkout</Title>
      {/* <SubTitle>Plan Details</SubTitle> */}
      <SectionContainer>
        <TextItem>
          <span>Plan Amount: </span>
          <span>
            {formatCurrency(planDetails.plan_Amt)} {planDetails?.currency}
          </span>
        </TextItem>
        <TextItem>
          <span>Duration: </span>
          {planDetails?.charge_Duration}
        </TextItem>
        {/* <TextItem>Subtotal: {formatCurrency(planDetails.subtotal)}</TextItem> */}
        <TextItem>
          <span>GST: </span> {formatCurrency(planDetails.gst_Amt)}
        </TextItem>
        <TextItem>
          <span>PST: </span> {formatCurrency(planDetails.pst_Amt)}
        </TextItem>
        <TextItem>
          <span>SIM card fee: </span> {formatCurrency(planDetails.simcard_amt)}
        </TextItem>
        <TextItem
          style={{ borderTop: "1px solid #BEBEBA", paddingTop: "10px" }}
        >
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>Total: </span>
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>
            {" "}
            {formatCurrency(planDetails.total)}
          </span>
        </TextItem>
      </SectionContainer>

      {/* <SubTitle>Summary</SubTitle>
      <SectionContainer>
        <TextItem>Name: {summaryDetails?.name}</TextItem>
        <TextItem>Email: {summaryDetails?.email}</TextItem>
        <TextItem>
          Activation Date: {formatDate(summaryDetails.activationDate)}
        </TextItem>
        <TextItem>Home Address: {address}</TextItem>
        <TextItem>Postal Code: {postalCode}</TextItem>
      </SectionContainer> */}

      <Button onClick={handleNext}>Proceed to Payment</Button>
    </div>
  );
};

export default Checkout;
