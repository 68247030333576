import styled from "styled-components";
import { useState } from "react";
import React from "react";
import { LuPhone, LuRedo, LuMail } from "react-icons/lu";
import { BsHouseDoor } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { colors } from "../../components/Constants";
import {
  BoxContent,
  LoadingIcon,
  Title,
  Button,
} from "../../components/StyledComponent";
const SupportCS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <Title>Customer Service</Title>
      <SupportCard>
        <SupportItem>
          <IconWrapper>
            <LuPhone size={20} />
          </IconWrapper>
          <Info>
            <Label>Phone Support</Label>
            <Detail href="tel:18334141033">1 (833) 414-1033</Detail>
          </Info>
        </SupportItem>

        <SupportItem>
          <IconWrapper>
            <LuMail size={20} />
          </IconWrapper>
          <Info>
            <Label>Email Support</Label>
            <EmailDetail href="mailto:support@thelightphone.com">
              support@thelightphone.com
            </EmailDetail>
          </Info>
        </SupportItem>

        <SupportItem>
          <IconWrapper>
            <BsHouseDoor size={20} />
          </IconWrapper>
          <Info>
            <Label>Office Address</Label>
            <Detail>Customer Service Team</Detail>
            <Detail>19 Morris Ave, Brooklyn, NY 11206</Detail>
          </Info>
        </SupportItem>
      </SupportCard>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SupportCard = styled.div`
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;

const SupportItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const IconWrapper = styled.div`
  background: ${colors.division};
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const Detail = styled.span`
  text-decoration: none;
  color: ${colors.description};
  font-size: 14px;
  margin-top: 3px;
`;

const EmailDetail = styled.a`
  color: ${colors.description};
  font-size: 14px;
  margin-top: 3px;
`;
export default SupportCS;
