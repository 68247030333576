import React from "react";
import styled from "styled-components";
import { SubTitle, Title } from "./StyledComponent";
import { colors } from "./Constants";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Description, LoadingIcon, HighlightedBox } from "./StyledComponent";
import { Input } from "./StyledComponent";
import { Button } from "./StyledComponent";
import { BreakPoint } from "./StyledComponent";
import { Box } from "./StyledComponent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignUpService from "../api/SignUpService";
const Plans = () => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  // const idToken = useSelector((state) => state.user.idToken);
  // const idToken = localStorage.getItem("idToken");
  const idToken = useSelector((state) => state.user.idToken);
  console.log("idToken from userSelector", idToken);
  const authToken = localStorage.getItem("idToken");
  console.log("idToken from localStorage", authToken);

  const location = useLocation();
  //ANCHOR - Get plans
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const response = await SignUpService.checkPlans(authToken);
        if (response) {
          setPlans(response);
          console.log("plans:", response);
        } else {
          setErrorText("No plans available.");
        }
      } catch (error) {
        console.error("Error fetching plans", error);
        setErrorText("Failed to load plans. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [idToken]);

  //ANCHOR - check plans
  const handlePlanClick = (plan) => {
    navigate("/plansDetail", {
      state: { plan },
    });
  };

  return (
    <div className="container">
      <Title>Select a Plan</Title>
      {loading ? (
        <LoadingContainer>
          <LoadingIcon style={{ fontSize: "34px" }} />
        </LoadingContainer>
      ) : plans.length > 0 ? (
        plans.map((plan, index) => (
          <HighlightedBox key={index} onClick={() => handlePlanClick(plan)}>
            <div>
              <span
                style={{
                  fontSize: "26px",
                  fontWeight: "bold",
                }}
              >
                ${plan.Fee}/month
              </span>
              <br />
              <span style={{ fontSize: "14px" }}>
                {plan.Calling}
                <br />
                {plan.Message}
                <br />
                {plan.DataCapacity} of Data
              </span>
            </div>
          </HighlightedBox>
        ))
      ) : (
        <p>{errorText}</p>
      )}
    </div>
  );
};
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default Plans;
