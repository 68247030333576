import styled from "styled-components";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Title, Box, Button } from "../../components/StyledComponent";
import { useDispatch } from "react-redux";
import { resetUser } from "../../api/UserSlice";
import { resetUserInfo } from "../../api/UserInfoSlice";
import { colors } from "../../components/Constants";
import { IoIosArrowForward } from "react-icons/io";
import {
  LuRefreshCcw,
  LuTag,
  LuFile,
  LuCreditCard,
  LuPhone,
} from "react-icons/lu";

const Subscription = () => {
  const navigate = useNavigate();
  const {
    phoneNumber,
    startDate,
    endDate,
    planPrice,
    dataCapacity,
    dataUsage,
    planPfId,
  } = useSelector((state) => state.userInfo);
  console.log("Redux State in subscription Page:", {
    phoneNumber,
    startDate,
    endDate,
    planPrice,
    dataCapacity,
    dataUsage,
    planPfId,
  });
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const formattedPhoneNumber = phoneNumber
      .replace(/\D/g, "")
      .match(/^(\d{3})(\d{3})(\d{4})$/);
    if (formattedPhoneNumber) {
      return `+1 (${formattedPhoneNumber[1]}) ${formattedPhoneNumber[2]}-${formattedPhoneNumber[3]}`;
    }
    return phoneNumber;
  };
  return (
    <div className="container">
      <Header>
        <Title>Light Plan</Title>
        <SettingsButton
          onClick={() => navigate("/subscription/subscriptionSetting")}
        >
          Subscription Settings
        </SettingsButton>
      </Header>
      <Phone>{formatPhoneNumber(phoneNumber)}</Phone>

      <Section>
        <SectionTitle>Usage</SectionTitle>
        <UsageInfo>
          <UsageUsed>
            <span style={{ fontSize: "16px" }}>{dataUsage}</span>
            <span>Used</span>
          </UsageUsed>
          <UsageTotal>{dataCapacity} GB total</UsageTotal>
        </UsageInfo>

        {/* <UsageBar>
          <UsageProgress style={{ width: "40%" }} />
        </UsageBar> */}

        <span
          style={{
            fontSize: "9px",
            color: colors.description,
            marginTop: "5px",
          }}
        >
          Data usage data can be delayed by up to 48 hours
        </span>
        {/* <AddDataBox>
          <AddDataTitle>Add Data</AddDataTitle>
          <DataOptions>
            <DataOption onClick={() => navigate("/subscription/topupCheckout")}>
              <DataAmount>2 GB</DataAmount>
              <DataPrice>for $20.00</DataPrice>
            </DataOption>
            <DataOption onClick={() => navigate("/subscription/topupCheckout")}>
              <DataAmount>1 GB</DataAmount>
              <DataPrice>for $10.00</DataPrice>
            </DataOption>
          </DataOptions>
        </AddDataBox> */}
      </Section>

      <Section>
        <SectionTitle
        // style={{ borderTop: "1px solid #D1D5DB", paddingTop: "20px" }}
        >
          Billing
        </SectionTitle>
        <DetailsList>
          <DetailItem>
            <DetailIcon>
              <LuRefreshCcw />
            </DetailIcon>
            <DetailText>
              <span>Next Renewal</span>
              <span>{endDate.split("T")[0]}</span>
            </DetailText>
          </DetailItem>
          <DetailItem>
            <DetailIcon>
              <LuTag />
            </DetailIcon>
            <DetailText>
              <span>Price</span>
              <span>{planPrice}</span>
            </DetailText>
          </DetailItem>
        </DetailsList>
      </Section>

      <Section style={{ borderTop: "none" }}>
        <SectionTitle>Subscription Details</SectionTitle>
        <DetailsList>
          {/* Phone Number */}
          <DetailItem>
            <DetailIcon>
              <LuPhone />
            </DetailIcon>
            <DetailText>
              <span>Phone Number</span>
              <span>{formatPhoneNumber(phoneNumber)}</span>
            </DetailText>
          </DetailItem>

          {/* Plan Details */}
          <DetailItem
            onClick={() => navigate("/subscription/subscription-plan")}
            style={{ cursor: "pointer" }}
          >
            <DetailIcon>
              <LuFile />
            </DetailIcon>
            <DetailText>
              <span>Plan Details</span>
              <span>Light Plan</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>
          {/* SIM ICCID */}
          <DetailItem
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/subscription/SIMcard")}
          >
            <DetailIcon>
              <LuCreditCard />
            </DetailIcon>
            <DetailText>
              <span>SIM ICCID #</span>
              <span>89014104274143188192</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>
        </DetailsList>
      </Section>
    </div>
  );
};

// Styled Components

const UsageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  gap: 8px;
`;

const UsageUsed = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  font-size: 12px;
  font-weight: bold;
`;

const UsageTotal = styled.div`
  font-size: 12px;
  color: ${colors.primary};
  font-weight: bold;
`;

const UsageBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.littleBackground};
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
`;

const UsageProgress = styled.div`
  height: 100%;
  background-color: ${colors.primary};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SettingsButton = styled.button`
  font-size: 11px;
  font-weight: bold;
  padding: 7px 10px;
  margin-bottom: 12px;
  background-color: ${colors.littleBackground};
  border-radius: 20px;
  border: none;
  cursor: pointer;
`;

const Phone = styled.div`
  font-size: 16px;
  color: ${colors.primary};
  margin: 10px 0;
`;

const Section = styled.div`
  border-top: 1px solid ${colors.division};
  margin-top: 20px;
  padding-top: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const AddDataBox = styled.div`
  background-color: ${colors.littleBackground};
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
`;

const AddDataTitle = styled.div`
  font-size: 14px;
  color: ${colors.primary};
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const DataOptions = styled.div`
  gap: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DataOption = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const DataAmount = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
const DataPrice = styled.div`
  font-size: 12px;
`;

const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.division};
  cursor: pointer;
`;

const DetailIcon = styled.span`
  font-size: 18px;
  margin-right: 10px;
`;

const DetailText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${colors.primary};

  & span:first-child {
    font-size: 12px;
    color: ${colors.description};
  }

  & span:last-child {
    font-size: 14px;
    color: ${colors.primary};
  }
`;

const Arrow = styled.span`
  font-size: 18px;
  color: ${colors.primary};
`;
export default Subscription;
