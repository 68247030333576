import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //personal info
  firstName: localStorage.getItem("firstName") || "",
  lastName: localStorage.getItem("lastName") || "",
  email: localStorage.getItem("email") || "",
  confirmEmail: localStorage.getItem("confirmEmail") || "",
  cnum: localStorage.getItem("cnum") || "",
  //address
  province: localStorage.getItem("province") || "",
  city: localStorage.getItem("city") || "",
  address: localStorage.getItem("address") || "",
  postalCode: localStorage.getItem("postalCode") || "",
  //for port-in
  phoneNumber: localStorage.getItem("phoneNumber") || "",
  carrier: localStorage.getItem("carrier") || "",
  accountNumber: localStorage.getItem("accountNumber") || "",
  imeiNumber: localStorage.getItem("imeiNumber") || "",
  portIn: localStorage.getItem("portIn") === "true",
  simCardNumber: localStorage.getItem("simCardNumber") || "",
  // plans related:
  planDetails: JSON.parse(localStorage.getItem("planDetails")) || {},
  summaryDetails: JSON.parse(localStorage.getItem("summaryDetails")) || {},
  startDate: localStorage.getItem("startDate") || "",
  endDate: localStorage.getItem("endDate") || "",
  planPrice: localStorage.getItem("planPrice") || "",
  dataCapacity: localStorage.getItem("dataCapacity") || "",
  dataUsage: localStorage.getItem("dataUsage") || "",
  planCarrier: localStorage.getItem("planCarrier") || "",
  planCalling: localStorage.getItem("planCalling") || "",
  planOverRate: localStorage.getItem("planOverRate") || "",
  planPfId: localStorage.getItem("planPfId") || "",
  simICCID: localStorage.getItem("simICCID") || "",

  //payment related:
  creditCardLast4Digits: localStorage.getItem("creditCardLast4Digits") || "",
  creditCardType: localStorage.getItem("creditCardType") || "",
  creditCardHolderName: localStorage.getItem("creditCardHolderName") || "",
  creditCardExpirationMonth:
    localStorage.getItem("creditCardExpirationMonth") || "",
  creditCardExpirationYear:
    localStorage.getItem("creditCardExpirationYear") || "",
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setConfirmEmail: (state, action) => {
      state.confirmEmail = action.payload;
    },
    setCnum: (state, action) => {
      state.cnum = action.payload;
    },
    setProvince: (state, action) => {
      state.province = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setPostalCode: (state, action) => {
      state.postalCode = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    planCarrier: (state, action) => {
      state.carrier = action.payload;
    },
    setAccountNumber: (state, action) => {
      state.accountNumber = action.payload;
    },
    setImeiNumber: (state, action) => {
      state.imeiNumber = action.payload;
    },
    setPortIn: (state, action) => {
      state.portIn = action.payload;
    },
    setSimCardNumber: (state, action) => {
      state.simCardNumber = action.payload;
    },
    // plans related
    setPlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
    setSummaryDetails: (state, action) => {
      state.summaryDetails = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setPlanPrice: (state, action) => {
      state.planPrice = action.payload;
    },
    setDataCapacity: (state, action) => {
      state.dataCapacity = action.payload;
    },
    setDataUsage: (state, action) => {
      state.dataUsage = action.payload;
    },
    setPlanCarrier: (state, action) => {
      state.planCarrier = action.payload;
    },
    setPlanCalling: (state, action) => {
      state.planCalling = action.payload;
    },
    setPlanOverRate: (state, action) => {
      state.planOverRate = action.payload;
    },
    setPlanPfId: (state, action) => {
      state.planPfId = action.payload;
    },
    setSimICCID: (state, action) => {
      state.simICCID = action.payload;
    },
    //payment related
    setCreditCardLast4Digits: (state, action) => {
      state.creditCardLast4Digits = action.payload;
    },
    setCreditCardType: (state, action) => {
      state.creditCardType = action.payload;
    },
    setCreditCardHolderName: (state, action) => {
      state.creditCardHolderName = action.payload;
    },
    setCreditCardExpirationMonth: (state, action) => {
      state.creditCardExpirationMonth = action.payload;
    },
    setCreditCardExpirationYear: (state, action) => {
      state.creditCardExpirationYear = action.payload;
    },
    // resetPaymentInfo: (state) => {
    //   state.creditCardLast4Digits = "";
    //   state.creditCardType = "";
    //   state.creditCardHolderName = "";
    //   state.creditCardExpirationMonth = "";
    //   state.creditCardExpirationYear = "";
    // },
    // Reset all user info
    resetUserInfo: (state) => {
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.confirmEmail = "";
      state.cnum = "";
      state.province = "";
      state.city = "";
      state.address = "";
      state.postalCode = "";
      state.phoneNumber = "";
      state.carrier = "";
      state.accountNumber = "";
      state.imeiNumber = "";
      state.portIn = false;
      state.simCardNumber = "";
      state.planDetails = {};
      state.summaryDetails = {};
      state.creditCardLast4Digits = "";
      state.creditCardType = "";
      state.creditCardHolderName = "";
      state.creditCardExpirationMonth = "";
      state.creditCardExpirationYear = "";
      state.startDate = "";
      state.endDate = "";
      state.planPrice = "";
      state.dataCapacity = "";
      state.dataUsage = "";
      state.planCarrier = "";
      state.simICCID = "";
      state.planCalling = "";
      state.planOverRate = "";
      state.planPfId = "";

      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("email");
      localStorage.removeItem("confirmEmail");
      localStorage.removeItem("cnum");
      localStorage.removeItem("province");
      localStorage.removeItem("city");
      localStorage.removeItem("address");
      localStorage.removeItem("postalCode");
      localStorage.removeItem("phoneNumber");
      localStorage.removeItem("carrier");
      localStorage.removeItem("accountNumber");
      localStorage.removeItem("imeiNumber");
      localStorage.removeItem("portIn");
      localStorage.removeItem("simCardNumber");
      localStorage.removeItem("planDetails");
      localStorage.removeItem("summaryDetails");
      localStorage.removeItem("startDate");
      localStorage.removeItem("endDate");
      localStorage.removeItem("planPrice");
      localStorage.removeItem("dataCapacity");
      localStorage.removeItem("dataUsage");
      localStorage.removeItem("planCarrier");
      localStorage.removeItem("simICCID");
      localStorage.removeItem("planCalling");
      localStorage.removeItem("planOverRate");
      localStorage.removeItem("planPfId");
      // localStorage.removeItem("creditCardLast4Digits");
      // localStorage.removeItem("creditCardType");
      // localStorage.removeItem("creditCardHolderName");
      // localStorage.removeItem("creditCardExpirationMonth");
      // localStorage.removeItem("creditCardExpirationYear");
    },
    storeUserInfoInLocalStorage: (state) => {
      localStorage.setItem("firstName", state.firstName);
      localStorage.setItem("lastName", state.lastName);
      localStorage.setItem("email", state.email);
      localStorage.setItem("confirmEmail", state.confirmEmail);
      localStorage.setItem("cnum", state.cnum);
      localStorage.setItem("province", state.province);
      localStorage.setItem("city", state.city);
      localStorage.setItem("address", state.address);
      localStorage.setItem("postalCode", state.postalCode);
      localStorage.setItem("phoneNumber", state.phoneNumber);
      localStorage.setItem("carrier", state.carrier);
      localStorage.setItem("accountNumber", state.accountNumber);
      localStorage.setItem("imeiNumber", state.imeiNumber);
      localStorage.setItem("portIn", state.portIn);
      localStorage.setItem("simCardNumber", state.simCardNumber);

      localStorage.setItem("planDetails", JSON.stringify(state.planDetails));
      localStorage.setItem(
        "summaryDetails",
        JSON.stringify(state.summaryDetails)
      );
      localStorage.setItem("startDate", state.startDate);
      localStorage.setItem("endDate", state.endDate);
      localStorage.setItem("planPrice", state.planPrice);
      localStorage.setItem("dataCapacity", state.dataCapacity);
      localStorage.setItem("dataUsage", state.dataUsage);
      localStorage.setItem("planCarrier", state.carrier);
      localStorage.setItem("simICCID", state.simICCID);
      localStorage.setItem("planCalling", state.planCalling);
      localStorage.setItem("planOverRate", state.planOverRate);
      localStorage.setItem("planPfId", state.planPfId);
      // localStorage.setItem(
      //   "creditCardLast4Digits",
      //   state.creditCardLast4Digits
      // );
      // localStorage.setItem("creditCardType", state.creditCardType);
      // localStorage.setItem("creditCardHolderName", state.creditCardHolderName);
      // localStorage.setItem(
      //   "creditCardExpirationMonth",
      //   state.creditCardExpirationMonth
      // );
      // localStorage.setItem(
      //   "creditCardExpirationYear",
      //   state.creditCardExpirationYear
      // );
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setEmail,
  setConfirmEmail,
  setCnum,
  setProvince,
  setCity,
  setAddress,
  setPostalCode,
  setPhoneNumber,
  setCarrier,
  setAccountNumber,
  setImeiNumber,
  setPortIn,
  setSimCardNumber,
  resetUserInfo,
  storeUserInfoInLocalStorage,
  setPlanDetails,
  setSummaryDetails,
  setStartDate,
  setEndDate,
  setCreditCardType,
  setCreditCardLast4Digits,
  setCreditCardHolderName,
  setCreditCardExpirationMonth,
  setCreditCardExpirationYear,
  setPlanPrice,
  setDataCapacity,
  setDataUsage,
  setPlanCarrier,
  setSimICCID,
  setPlanCalling,
  setPlanOverRate,
  setPlanPfId,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
